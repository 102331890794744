import React, { Component, Fragment } from 'react';
import { Tab, Row, Col, Nav, Table,  } from 'react-bootstrap';
import './Sidebar.css';


function getFormmatedPrice(price, currency) {
    if (currency == "eur") {
        var amount = (Math.round(price * 100) / 100).toFixed(2);
        return amount.replace(".", ",") + "€";
    } else {
        return "Rs." + price
    }
}

export default class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            catagory: []
                }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.menuDetails !== this.props.menuDetails) {
            // console.log('UpdatingModel');
            // console.log('prevProps', prevProps);
            // console.log('current>>>>', this.props.menuDetails);
            this.setState({
                catagory: this.props.menuDetails,
                resturentDetails: this.props.restroDetails,
                firstItemID: this.props.menuDetails[0].id
            })
            this.render()

            /* your setState logic*/
        }
    }





    render() {
        // let local = this.props.menuDetails;
        // const [bf,lunch,snacks,dinner] = local;
        // console.log('Breakfast');
        return (
            <Fragment>
                {/* {console.log('Default Key.....', this.state.defaultKey)} */}
                
                {/* {this.setState({
                       catagory: this.props.menuDetails
                    })} */}

                <aside className="">
                    {console.log(this.state.firstItemID)}
                        <Tab.Container id="left-tabs-example" defaultActiveKey={this.state.firstItemID}>
                            <Row>
                                <Col sm={3} xs={4} className="p-0 sideBar">
                                    <Nav variant="pills" className="flex-column" >


                                        {this.state.catagory.map((catagory, index) => {
                                            return (
                                                <Nav.Item className="CatList" key={index} >
                                                    <Nav.Link key={index} eventKey={catagory.id}>
                                                        {catagory.dispalyName}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            )
                                        })}

                                    </Nav>
                                </Col>

                                <Col sm={9} xs={8} className="px-0" >
                                    <div className="menuDetails">
                                        <Tab.Content >
                                            {this.state.catagory.map((catagory) => {
                                                return (
                                                    <Tab.Pane className="TabPennal" eventKey={catagory.id} >
                                                        <Table striped   size="sm">
                                                        
                                                            <tbody>
                                                                {catagory.menuItem.map((menu) => {
                                                                    let formatedPrice = getFormmatedPrice(menu.price, this.state.resturentDetails.currency)
                                                                    return(
                                                                    <tr>
                                                                        <td>
                                                                            <div className="row m-0">
                                                                                <div className="col-2 col-md-1 itemDisplyIDDiv">
                                                                                <p className="itemDisplyID">{menu.itemDispalyID}</p>
                                                                                </div>
                                                                                <div className="col-10 col-md-11 pl-0">
                                                                                <div className="d-flex justify-content-between w-100 ">
                                                                           <p className="font-weight-bold m-0 ">{menu.displayName}</p>
                                                                           <p className="m-0 dishPrize mr-4 font-weight-bold">{formatedPrice}</p>
                                                                               </div> 
                                                                        <p className="m-0 dishDescription">{menu.description}</p>
                                                                        <p className="m-0 dishSecondryDescription">{menu.secoundryDescription}</p>
                                                                                </div>
                                                                            </div>
                                                                           
                                                                        
                                                                        </td>
                                                                    </tr>)
                                                                })}

                                                            </tbody>
                                                        </Table>

                                                    </Tab.Pane>
                                                )
                                            })}
                                        </Tab.Content>
                                    </div>
                                </Col>
                            </Row>
                        </Tab.Container>
                  
                </aside>

            </Fragment>
        )
    }
}
