import React from 'react'
import './Footer.css';
export default function Footer() {
    return (
        <div>
            <div className="footer  d-flex justify-content-center align-items-center align-content-center">
                <p className="text-center m-0"><i className="fa fa-copyright"></i> Techvanto Pvt. Ltd.</p>
            </div>
        </div>
    )
}
