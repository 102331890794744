import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, Link, BrowserRouter} from 'react-router-dom'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Modal from 'react-modal'
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
Modal.setAppElement('#root')

const routing = (
  <BrowserRouter>
    <Switch>
      <Route exect path='/:restorentID' component={App} />
    </Switch>
  </BrowserRouter>
)


ReactDOM.render(
  
  routing
  ,
  document.getElementById('root')
);




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();



