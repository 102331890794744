import React, { Fragment } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import './Header.css'
export default function Header(props) {
    return (
        <Fragment>
            {/* {console.log('Header props',props)} */}

            <Navbar className="header" expand="md">
                <Container>
                    <Navbar.Brand className="font-weight-bolder" href="#home">
                        {props.restroName}
                        <small> {props.restroTagLine}</small>
                    </Navbar.Brand>
                    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">

                            {/* <Nav.Link className="text-white">{props.restroAddress}</Nav.Link> */}
                        </Nav>

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Fragment>
    )
}
