/* eslint-disable no-undef */
/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import Loader from 'react-loader-spinner'
import './App.css';
import Header from './common/Header/Header';
import Sidebar from './common/Sidebar/Sidebar';
import 'font-awesome/css/font-awesome.min.css';
import Footer from './common/Footer/Footer';
import Modal from 'react-modal';


const axios = require('axios');




export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      restroDetails: null,
      menuDetails: [],
      isLoader: true,
      isModalOpen: false,
    }


    // const pathname = this.props;

  }


  componentDidMount() {



    console.log('DID Mount>>>>>>>>>>>>>>>');
    //  restro details fetch
    const { match: { params } } = this.props;
    console.log(params.restorentID)
    axios.get(`https://dev.api.techvanto.com/resturant/getResturentDetails?resturentID=${params.restorentID}`).then(response => {
      this.setState({
        restroDetails: response.data.data,
        isLoader: false
      })

    }).catch(error => {
      // handle error
      if (error.status !== 200) {
        // alert();
        this.setState({
          isModalOpen: true,
          isLoader: true
        })

      }
      // console.log('API ERROR', error);
    })


    //  Menu details fetch
    axios.get(`https://dev.api.techvanto.com/resturant/getMenuDetails?resturentID=${params.restorentID}`).then(response => {

      this.setState({
        menuDetails: response.data.data,
        isLoader: false
      })

    }).catch(error => {
    })
  }

  render() {
    const customStyles = {
      overlay: {
        backgroundColor: '#ddd',
        
      },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection:'column',
        justifyCcontent: 'center',
        textAlign:'center'
      }
    };
    // console.log('render called',this.state.menuDetails);
    return (

      <div className="App">
        <Modal
          isOpen={this.state.isModalOpen}
          style={customStyles}
          contentLabel="Example Modal"
        >

          <h1>Opss....</h1>
          <h2>Restaurant not found</h2>
          <h3>Please Contact to restaurant. QR code is not valid</h3>
        

        </Modal>
        {this.state.isLoader ? <Loader className="loader" type="Circles" color="#00BFFF" height={100} width={100} /> :

          <div>
            <Header restroName={this.state.restroDetails.dispalyName} restroTagLine={this.state.restroDetails.tagLine} restroAddress={this.state.restroDetails.address} />
            <Sidebar menuDetails={this.state.menuDetails} restroDetails={this.state.restroDetails}/>
            <Footer></Footer>
          </div>
        }

      </div>
    )
  }
}



